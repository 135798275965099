<template>
  <div>
    <v-row>
      <v-col cols="12" sm="8" md="8" lg="8" xl="8">
        <v-card class="message-card">
          <v-tabs v-model="tab" show-arrows fixed-tabs color="primary" centered icons-and-text
            style="margin-bottom:10px;">
            <v-tab href="#w" @click="changeType('微信号','weixin')">
              QQ/微信号
              <v-icon>mdi-wechat</v-icon>
            </v-tab>

            <v-tab href="#m" @click="changeType('邮箱','email')">
              邮箱
              <v-icon>mdi-email</v-icon>
            </v-tab>

            <v-tab href="#u" @click="changeType('网址','web')">
              网址
              <v-icon>mdi-web</v-icon>
            </v-tab>
            <v-tab href="#t" @click="changeType('文本','text')">
              文本
              <v-icon>mdi-text</v-icon>
            </v-tab>
          </v-tabs>
          <div>
            <v-textarea v-if="targetGenerateType=='email'" v-model="inputEmail" outlined name="input-7-4"
              :label="targetGenerateName" height="111"></v-textarea>
            <div class="hint-box" v-if="targetGenerateType=='email'&&emails.length>0">
              <div @click="setInput(mail)" class="hint-item" v-for="mail in emails" :key="mail">{{mail}}</div>
            </div>
            <v-textarea :counter="maxLength" :maxLength="maxLength" v-if="targetGenerateType!='email'" outlined name="input-7-4"
              :label="targetGenerateName" v-model="originText" height="111">
            </v-textarea>
            <!-- <div v-if="targetGenerateType!='email'" style="margin-top:-20px;color:#999;text-align:right;">{{originText.length}}/{{maxLength}}</div> -->
            <v-select v-model="duration" :items="durationItems" label="有效时间" item-text="label" item-value="value">
            </v-select>
            <v-btn :loading="isLoading" :disabled="isDisabled" @click="generateToken()" block color="primary">生成
            </v-btn>
          </div>
        </v-card>
      </v-col>
      <v-col cols="12" sm="4" md="4" lg="4" xl="4">
        <v-card class="message-card" style="text-align:center;">
          <vue-qr :text="targetUrl" qid="testid" style="width:100%;"></vue-qr>
          <v-text-field v-model="targetUrl" :append-outer-icon="'mdi-content-copy'" filled label="地址" type="text"
            @click:append-outer="copyContent(targetUrl)"></v-text-field>
        </v-card>
      </v-col>
    </v-row>
    <v-snackbar v-model="snackbar" top>
      {{ snackbarText }}
      <template v-slot:action="{ attrs }">
        <v-btn color="pink" text v-bind="attrs" @click="snackbar = false">
          关闭
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
  import Vue from 'vue'
  import VueQr from 'vue-qr/src/packages/vue-qr.vue'
  import common from '../service/common';
  // import HelloWorld from '../components/HelloWorld.vue'

  export default Vue.extend({
    name: 'Home',
    components: {
      VueQr
    },

    // components: {
    //   HelloWorld,
    // },
    data() {
      return {
        maxLength: 20,
        inputEmail: "",
        emailSuffix: "gmail.com",
        emailSuffixItems: [],
        isLoading: false,
        isDisabled: false,
        snackbar: false,
        snackbarText: "",

        targetGenerateName: "微信号",
        targetGenerateType: "weixin",
        originText: "",
        password: "",
        duration: 3600,
        tab: "",
        expireList: [],
        durationItems: [
          // {
          //   label: "一个月",
          //   value: 1
          // },
          // {
          //   label: "三个月",
          //   value: 3
          // },
          // {
          //   label: "六个月",
          //   value: 6
          // },
          // {
          //   label: "十二个月",
          //   value: 12
          // },
          // {
          //   label: "二十四个月",
          //   value: 24
          // },
        ],
        result: "",
        targetUrl: "",
      }
    },
    computed: {
      emails() {
        // 如果inputEmail中没有任何东西，则不处理
        if (!this.inputEmail) return []
        // 考虑到用户自己要输@符号，如果@符号首次出现的位置大于等于第零个位置时，则不处理
        if (this.inputEmail.indexOf('@') > -1) return []
        let inputEmailArr = [];

        this.emailSuffixItems.forEach(item => {
          inputEmailArr.push(this.inputEmail + "@" + item);
        })
        return inputEmailArr;
      }
    },
    methods: {
      // calculateNormalTextarea(){
      //   console.log(this.originText.length)
      //   console.log(this.originText);
      // },
      setInput(mail) {
        this.inputEmail = mail
      },
      copyContent(data) {
        let url = data;
        let oInput = document.createElement('input');
        oInput.value = url;
        document.body.appendChild(oInput);
        oInput.select(); // 选择对象;
        document.execCommand("Copy"); // 执行浏览器复制命令
        // this.$Message.success("复制成功！");
        this.snackbar = true;
        this.snackbarText = "复制成功！";
        oInput.remove()
      },
      changeType(name, type) {
        this.originText = "";
        this.inputEmail = "";
        this.targetGenerateName = name;
        this.targetGenerateType = type;
        if (this.targetGenerateType == "weixin") {
          this.maxLength = 20;
        }else if (this.targetGenerateType == "web") {
          this.maxLength = 1024;
        } else {
          this.maxLength = 100;
        }
        this.getExpireList();
      },
      generateToken() {
        this.isLoading = true;
        this.isDisabled = true;
        if (this.targetGenerateType == "weixin") {
          this.gWeixinToken();
        }
        if (this.targetGenerateType == "email") {
          this.gMailToken();
        }
        if (this.targetGenerateType == "web") {
          this.gUrlToken();
        }
        if (this.targetGenerateType == "text") {
          this.gTextToken();
        }
      },
      gWeixinToken() {
        const jsonData = {
          "weixin": this.originText,
          "password": this.password,
          "expire": parseInt(this.duration),
        }
        this.$service.generate.generateWeixinService(jsonData)
          .then(res => {
            this.isLoading = false;
            this.isDisabled = false;
            if (res['code'] == 0) {
              this.result = res['data'];
              this.targetUrl = process.env.VUE_APP_WEB_URL + encodeURIComponent(this.result);
            } else {
              this.snackbarText = res['message'];
              this.snackbar = true;
            }
          })
      },
      gUrlToken() {
        const jsonData = {
          "url": this.originText,
          "password": this.password,
          "expire": parseInt(this.duration),
        }
        this.$service.generate.generateUrlService(jsonData)
          .then(res => {
            this.isLoading = false;
            this.isDisabled = false;
            if (res['code'] == 0) {
              this.result = res['data'];
              this.targetUrl = process.env.VUE_APP_WEB_URL + encodeURIComponent(this.result);
            } else {
              this.snackbarText = res['message'];
              this.snackbar = true;
            }
          })
      },
      gTextToken() {
        const jsonData = {
          "text": this.originText,
          "password": this.password,
          "expire": parseInt(this.duration),
        }
        this.$service.generate.generateTextService(jsonData)
          .then(res => {
            this.isLoading = false;
            this.isDisabled = false;
            if (res['code'] == 0) {
              this.result = res['data'];
              this.targetUrl = process.env.VUE_APP_WEB_URL + encodeURIComponent(this.result);
            } else {
              this.snackbarText = res['message'];
              this.snackbar = true;
            }
          })
      },
      gMailToken() {
        const jsonData = {
          "mail": this.inputEmail,
          "password": this.password,
          "expire": parseInt(this.duration),
        }
        // console.log(jsonData)
        this.$service.generate.generateMailService(jsonData)
          .then(res => {
            this.isLoading = false;
            this.isDisabled = false;
            if (res['code'] == 0) {
              this.result = res['data'];
              this.targetUrl = process.env.VUE_APP_WEB_URL + encodeURIComponent(this.result);
            } else {
              this.snackbarText = res['message'];
              this.snackbar = true;
            }
          })
      },
      // getUserInfo() {
      //   this.$service.auth.getUserInfoService()
      //     .then(res => {
      //       if (res['code'] == 0) {
      //         this.durationItems.push({
      //           label: "永久",
      //           value: -1
      //         });
      //       }
      //     })
      // },
      getEmailSuffix() {
        this.$service.auth.getEmailSuffixService()
          .then(res => {
            if (res['code'] == 0) {
              this.emailSuffixItems = res['data'];
            }
          })
      },
      getExpireList() {
        this.durationItems = [];
        this.$service.common.getExpireListService()
          .then(res => {
            // console.log(res['data'])
            // console.log(Object.entries(res['data']))
            this.expireList = Object.entries(res['data'])
            // console.log(res);
            this.expireList.forEach(item => {
              this.durationItems.push({
                label: item[1],
                value: item[0]
              })
            });
            if (this.targetGenerateType == "text") {
              this.durationItems.splice(0, 1);
            }
            this.duration = this.durationItems[0]['value'];
            // console.log(this.duration);
            // this.expireList=res['data'];
          })
      }
    },
    mounted() {
      this.targetUrl = process.env.VUE_APP_WEB_URL;
      // this.getUserInfo();
      this.getEmailSuffix();
      this.getExpireList();
    }
  })
</script>

<style>
  .hint-box {
    /* margin-bottom: 30px; */
    /* background-color: white;  */
    margin-top: -100px;
    margin-left: 10px;
    border-radius: 4px;
    max-height: 120px;
    overflow-y: scroll;
    overflow-x: auto;
    position: absolute;
    z-index: 999;
    border: solid 1px #999;
    padding: 10px;
    background-color: white;
    /* width: 90%; */
  }

  .hint-item {
    cursor: pointer;
    color: #898989;
  }
</style>