<template>
  <div>
    <v-row>
      <v-col cols="12" sm="8" md="8" lg="8" xl="8">
        <v-card class="message-card">
          <v-btn block color="primary" @click="navigateHome()" style="margin-bottom:20px;">创建新链接</v-btn>
          <div>
            <div
              style="position:relative;width: 100%;min-height: 123px;border:solid 1px #007bff;border-radius: 6px;padding:10px;">
              <div>{{originText}}</div>
              <div style="position:absolute;right:0;bottom:0;">
                <v-btn text color="primary" @click="copyContent(originText)">
                  复制
                </v-btn>
                <v-btn text color="primary" v-if="targetTab=='u'" @click="windowOpen(originText)">
                  跳转
                </v-btn>
                <v-btn text color="primary" v-if="targetTab=='m'" @click="mailTo(originText)">
                  回复
                </v-btn>
              </div>
            </div>
          </div>
        </v-card>
      </v-col>
      <v-col cols="12" sm="4" md="4" lg="4" xl="4">
        <v-card class="message-card" style="text-align:center;">
          <vue-qr :text="targetUrl" :callback="test" qid="testid" style="width:100%;"></vue-qr>
          <v-text-field v-model="targetUrl" :append-outer-icon="'mdi-content-copy'" filled label="地址" type="text"
            @click:append-outer="copyContent(targetUrl)"></v-text-field>
        </v-card>
      </v-col>
    </v-row>
    <v-snackbar v-model="snackbar" top>
      {{ snackbarText }}
      <template v-slot:action="{ attrs }">
        <v-btn color="pink" text v-bind="attrs" @click="snackbar = false">
          关闭
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
  import Vue from 'vue';
  import VueQr from 'vue-qr/src/packages/vue-qr.vue';

  export default Vue.extend({
    name: 'Home',
    components: {
      VueQr
    },
    data() {
      return {
        snackbar: false,
        snackbarText: "",
        targetGenerateName: "微信号",
        targetGenerateType: "weixin",
        originText: "加载中...",
        tab: "",
        targetTab: "",
        items: ['Foo', 'Bar', 'Fizz', 'Buzz'],
        result: "",
        targetUrl: "",
      }
    },
    methods: {
      copyContent(data) {
        let url = data;
        let oInput = document.createElement('input');
        oInput.value = url;
        document.body.appendChild(oInput);
        oInput.select(); // 选择对象;
        console.log(oInput.value)
        document.execCommand("Copy"); // 执行浏览器复制命令
        this.snackbar = true;
        this.snackbarText = "复制成功！";
        oInput.remove()
      },
      navigateHome() {
        this.$router.push("/")
      },
      test(dataUrl, id) {
        console.log(dataUrl, id)
      },
      descryptWeixin() {
        this.$service.descrypt.descryptWeixinService(encodeURIComponent(this.$route.params.token))
          .then(res => {
            if (res['code'] == 0) {
              this.result = res['data'];
              this.originText = this.result;
            } else {
              this.snackbarText = res['message'];
              this.snackbar = true;
            }
          })
      },
      descryptUrl() {
        this.$service.descrypt.descryptUrlService(encodeURIComponent(this.$route.params.token))
          .then(res => {
            if (res['code'] == 0) {
              this.result = res['data'];
              this.originText = this.result;
            } else {
              this.snackbarText = res['message'];
              this.snackbar = true;
            }
          })
      },
      windowOpen(url) {
        if (url.indexOf("http") != -1) {
          window.location.href = url;
        } else {
          window.location.href = "https://" + url;
        }
      },
      descryptText() {
        this.$service.descrypt.descryptTextService(encodeURIComponent(this.$route.params.token))
          .then(res => {
            if (res['code'] == 0) {
              this.result = res['data'];
              this.originText = this.result;
            } else {
              this.snackbarText = res['message'];
              this.snackbar = true;
            }
          })
      },
      mailTo(email) {
        window.location.href = 'mailto:' + email;
      },
      descryptMail() {
        console.log(encodeURIComponent(this.$route.params.token))
        this.$service.descrypt.descryptMailService(encodeURIComponent(this.$route.params.token))
          .then(res => {
            if (res['code'] == 0) {
              this.result = res['data'];
              this.originText = this.result;
            } else {
              this.snackbarText = res['message'];
              this.snackbar = true;
            }
          })
      }
    },
    mounted() {
      const shareType = [{
          label: "weixin",
          value: "_"
        },
        {
          label: "mail",
          value: "-"
        },
        {
          label: "url",
          value: "."
        },
        {
          label: "text",
          value: "!"
        },
      ]
      console.log(this.$route.params.token)
      let token = this.$route.params.token
      if (token != "") {
        let firstStr = token.substring(0, 1);
        console.log(firstStr)
        if (firstStr == "_") {
          this.targetGenerateName = "微信";
          this.tab = "w";
          this.targetTab = "w";
          this.descryptWeixin();
        } else if (firstStr == ".") {
          this.targetGenerateName = "网址";
          this.tab = "u";
          this.targetTab = "u";
          this.descryptUrl();
        } else if (firstStr == "!") {
          this.targetGenerateName = "文本";
          this.tab = "t";
          this.targetTab = "t";
          this.descryptText();
        } else if (firstStr == "-") {
          this.targetGenerateName = "邮箱";
          this.tab = "m";
          this.targetTab = "m";
          this.descryptMail();
        }
      } else {
        this.$router.push("/");
      }
      this.targetUrl = process.env.VUE_APP_WEB_URL + encodeURIComponent(token);
    }
  })
</script>