<template>
    <div>
        <v-row>
            <v-col cols="12">
                <v-text-field label="搜索" outlined clearable :append-outer-icon="'mdi-magnify'" v-model="searchStr"
                    @click:append-outer="searchItemList"></v-text-field>
            </v-col>
            <!-- <v-col cols="2">
                <v-btn large>搜索</v-btn>
            </v-col> -->
        </v-row>
        <v-card v-for="item,i in list" :key="i" style="margin-bottom: 20px;">
            <v-card-title>
                {{item.text}}
            </v-card-title>
            <v-card-subtitle>
                <div>创建于{{item.create_time|filterCreateTime}}</div>
                <div>过期于{{item.expire_time|filterExpireTime}}</div>
            </v-card-subtitle>
            <v-card-text>
                <v-text-field v-model="item.text_encode" :append-outer-icon="'mdi-content-copy'" filled label="地址"
                    type="text" @click:append-outer="copyContent(item.text_encode)"></v-text-field>
            </v-card-text>
            <!-- <v-card-text>
                <v-chip color="primary" outlined>
                    微信号
                </v-chip>
            </v-card-text> -->
            <v-card-actions>
                <!-- <v-btn color="primary" text>
                    查看
                </v-btn> -->
                <v-btn color="red" text @click="deleteText(item.id)">
                    删除
                </v-btn>
            </v-card-actions>
        </v-card>
        <v-dialog v-model="deleteDialog" persistent max-width="290">
            <v-card>
                <v-card-title class="text-h5">
                    确认删除?
                </v-card-title>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="green darken-1" text @click="deleteDialog = false">
                        取消
                    </v-btn>
                    <v-btn color="red darken-1" text @click="onDelete">
                        确认
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-snackbar v-model="snackbar" top>
            {{ snackbarText }}

            <template v-slot:action="{ attrs }">
                <v-btn color="pink" text v-bind="attrs" @click="snackbar = false">
                    关闭
                </v-btn>
            </template>
        </v-snackbar>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                snackbar: false,
                snackbarText: "",
                webPath: process.env.VUE_APP_WEB_URL,
                searchStr: "",
                deleteDialog: false,
                list: [],
                targetId: "",
                page: 1,
                pageSize: 5,
                targetUrl: "",
            }
        },
        filters: {
            filterCreateTime(v) {
                //   return "123"
                let createTime = new Date(v);
                let targetTime =
                    `${createTime.getFullYear()}-${createTime.getMonth()+1}-${createTime.getDate()} ${createTime.getHours()}:${createTime.getMinutes()}`;
                return targetTime
            },
            filterExpireTime(v) {
                let createTime = new Date(v);
                let targetTime =
                    `${createTime.getFullYear()}-${createTime.getMonth()+1}-${createTime.getDate()} ${createTime.getHours()}:${createTime.getMinutes()}`;
                return targetTime
            }
            // filterExpireTime(v, itemCreateTime) {
            //     if (v != -1) {
            //         let createTime = new Date(itemCreateTime);
            //         createTime.setSeconds(createTime.getMonth() + v)
            //         let targetTime =
            //             `${createTime.getFullYear()}-${createTime.getMonth()+1}-${createTime.getDate()} ${createTime.getHours()}:${createTime.getMinutes()}`;
            //         return targetTime
            //     } else {
            //         return "永久";
            //     }
            // }
        },
        methods: {
            copyContent(data) {
                let url = data;
                let oInput = document.createElement('input');
                oInput.value = url;
                document.body.appendChild(oInput);
                oInput.select(); // 选择对象;
                console.log(oInput.value)
                document.execCommand("Copy"); // 执行浏览器复制命令
                this.snackbar = true;
                this.snackbarText = "复制成功！";
                oInput.remove()
            },
            sendMessage() {
                console.log("123")
            },
            searchItemList() {
                if (this.searchStr == null || this.searchStr == undefined) {
                    this.searchStr = "";
                }
                this.page = 1;
                this.list = [];
                this.getList();
            },
            getList() {
                this.$service.text.getTextListService(this.searchStr, this.page, this.pageSize)
                    .then(res => {
                        if (res['code'] == 0) {
                            res['data'].forEach(item => {
                                item['text_encode'] = this.webPath + item['text_encode'];
                            });
                            this.list.push(...res['data']);
                        }
                    })
            },
            deleteText(id) {
                this.targetId = id;
                this.deleteDialog = true;
            },
            onDelete() {
                this.$service.text.deleteService(this.targetId)
                    .then(res => {
                        this.list=[];
                        this.getList()
                        this.deleteDialog = false;
                    })
            },
            onScroll() {
                let offsetH = document.documentElement.offsetHeight;
                let scrollT = document.documentElement.scrollTop;
                let height = document.documentElement.scrollHeight;
                //div 距离底部 = 列表的总高度 -（滚动的距离 + 窗口可视高度）
                let bottom = height - (scrollT + offsetH);
                // console.log(offsetH,scrollT,height,bottom);
                if (bottom < 100 && !this.getBottom && !this.noMore) {
                    this.getBottom = true;
                    this.page++;
                    this.getList();
                }
            },
            getUserInfo() {
                this.$service.auth.getUserInfoService()
                    .then(res => {
                        if (res['code'] != 0) {
                            this.$router.push("/");
                        }
                    })
            },
        },
        mounted() {
            this.getUserInfo();
            this.getList();
            window.addEventListener('scroll', this.onScroll);
        }
    }
</script>